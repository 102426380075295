import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background:
    "linear-gradient(180deg, #7FCCF8 3.65%, rgba(101, 160, 193, 0.96) 72.92%)",
  fontSize: 20,
  fontWeight: 800,
  padding: "12px 20px",
  borderRadius: 4,
  textTransform: "uppercase",
  color: Style.colors.white,
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      border: `1px solid ${Style.colors.primary}`,
      color: Style.colors.primary,
      background: Style.colors.white,
    },
  },
  width: "fit-content",
  cursor: "pointer",
});

export const link = s({
  color: "inherit",
  textDecoration: "none",
});
