import * as React from "react";
import * as style from "./Service.style";
import * as Components from "../../components";
import { Title } from "../../components/Title";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";
import { useLocation, useParams, useNavigate } from "react-router-dom";

const Service: React.FC = () => {
  const display = hooks.useDisplayContext();
  const location = useLocation();
  const params = useParams();
  const { service } = params;
  const serviceDetails = serviceMap[service];
  const navigate = useNavigate();
  if (!serviceDetails) {
    navigate("/services");
  }
  const goToQuote = () => {
    var element = document.getElementById("get-a-quote");
    if (!!element) {
      element.scrollIntoView();
    }
  };
  return (
    <>
      <div className={style.component}>
        <div className={style.titleComponent}>
          <div className={style.overlay} />
          <div className={style.content}>
            <div className={style.title}>Services</div>
            <div className={style.subTitle}>
              <span style={{ fontWeight: 800 }}>/</span> {serviceDetails.title}
            </div>
          </div>
        </div>
        <div className={style.bottomContent}>
          <div className={style.product}>
            {!display.isMobile && (
              <div className={style.serviceProductImages}>
                {serviceDetails.images.map((image) => (
                  <IKImage path={image} alt="salida christmas rope lights" />
                ))}
              </div>
            )}
            <div className={style.productInfo}>
              <div className={style.productTitle}>
                {serviceDetails.subTitle}
              </div>
              <div className={style.productSubtitle}>
                {serviceDetails.miniTitle}
              </div>
              {serviceDetails.content.map((content) => (
                <div className={style.productDescription}>{content}</div>
              ))}
            </div>
            <div className={style.productQuoteLink} onClick={goToQuote}>
              Schedule an estimate now! &gt;
            </div>
          </div>
          <div className={style.tiles}>
            {service != "roof" && (
              <Components.ServiceTile.Roof
                className={style.tile}
                link="/service/roof"
              />
            )}
            {service != "fence" && (
              <Components.ServiceTile.Fence
                className={style.tile}
                link="/service/fence"
              />
            )}
            {service != "tree" && (
              <Components.ServiceTile.Tree
                className={style.tile}
                link="/service/tree"
              />
            )}
            {service != "patio" && (
              <Components.ServiceTile.Patio
                className={style.tile}
                link="/service/patio"
              />
            )}
            {service != "ground" && (
              <Components.ServiceTile.Ground
                className={style.tile}
                link="/service/ground"
              />
            )}
          </div>
        </div>
        <div className={style.component}>
          <Title title="Products We Use" className={style.pageSubTitle} />
          <div className={style.productsSection}>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/salida-christmas-rope-lights.png"
                    alt="salida christmas rope lights"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/rope-lights-1.png"
                      alt="christmas lights hanging bv colorado"
                    />
                    <IKImage
                      path="/products/rope-lights-2.png"
                      alt="buena vista colorado rope lights lighting"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>LED Rope Lights</div>
                <div className={style.productDescription}>
                  We use our LED rope lights for tree and bush wraps as well as
                  for walkway and lawn accent lighting. Our LED lights use a
                  vertical bulb so that the LEDs point out of, rather than down,
                  the line of rope light -- resulting in a greater angle of
                  illumination that is more eye-catching and impressive. We keep
                  warm white, red and green in stock, and can custom order blue,
                  orange and yellow. These lights are custom cut to work for any
                  space.
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/colorado-lights-hanging-business.png"
                    alt="salida christmas rope lights"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/christmas-lights-hanging-bv-colorado.png"
                      alt="christmas lights hanging bv colorado"
                    />
                    <IKImage
                      path="/products/buena-vista-colorado-roof-lighting.png"
                      alt="buena vista colorado roof lighting"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>LED String Lights</div>
                <div className={style.productDescription}>
                  We offer best in class C9 LED lights in any custom combination
                  of Warm White, Pure White, Red, Amber, Blue, Green and
                  Multicolored Bulbs. We offer multiple bulb spacing options to
                  provide the best look and price for your home. These energy
                  efficient C9 LED light bulbs are beautiful, super bright,
                  versatile, and will shine for 50,000 hours. The reasoning is
                  to save you money; incandescent bulbs use significantly more
                  power than LED bulbs and are much more fragile than these
                  sturdy, multifaceted LED bulbs that do not break, chip, or
                  fade.
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/outdoor-lights-hanging.png"
                    alt="salida christmas rope lights"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/chaffee-county-christmas-lights.png"
                      alt="christmas lights hanging bv colorado"
                    />
                    <IKImage
                      path="/products/chaffee-county-xmas-lights.png"
                      alt="buena vista colorado roof lighting"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>
                  S14 Shatterproof LED String Lights
                </div>
                <div className={style.productDescription}>
                  We carry this product with bulbs in warm white on black and
                  white strings. Individual light strings are connectable and
                  consist of 24 hanging bulbs on 48’ strings. hese Warm White
                  LED S14 light strings have long-lasting 15,000 hour bulbs and
                  are on a heavy duty 16 gauge black wire stringer. Each socket
                  is made with PVC plastic to provide a water-tight seal and to
                  ensure no defects due to weather. These durable and heavy duty
                  light strings will provide you with years of maintenance free
                  use.
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/colorado-wreath-and-garland.png"
                    alt="salida wreath and garland"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/colorado-wreath.png"
                      alt="wreath hanging colorado"
                    />
                    <IKImage
                      path="/products/colorado-garland.png"
                      alt="buena vista colorado local garland"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>
                  Colorado Pine Wreath and Garland
                </div>
                <div className={style.productDescription}>
                  Constructed with heavy duty wire frames to ensure years of
                  use, we offer artificial Colorado Pine garland and wreaths. We
                  offer wreaths in multiple sizes and all garland is trimmed to
                  length and priced by the foot. We also offer garland and
                  wreaths with preinstalled warm white LEDs (100 bulbs in every
                  nine-foot section of garland).
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/wide-angle-tree-lights.jpg"
                    alt="salid christmas rope lights"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/wide-angle-lights.jpg"
                      alt="christmas lights hanging bv colorado"
                    />
                    <IKImage
                      path="/products/wide-angle-mini-leds.jpg"
                      alt="buena vista colorado roof lighting"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>
                  Wide Angle Mini-LED Lights
                </div>
                <div className={style.productDescription}>
                  Our mini-LED lights are ideal for tree wraps, bushes, and look
                  great on railings and balconies. These heavy-duty LED
                  Christmas light sets use a molded high-quality one-piece LED
                  bulb construction so the bulbs cannot be pulled out or break,
                  providing years of maintenance free use. Each LED uses a
                  patented concave design making these LEDs viewable from all
                  angles. Our strings contain 100 LED lights per 50’ of wire. We
                  carry warm white and multi-color. Energy Star Certified and UL
                  listed for indoor and outdoor use
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
          </div>
          <div className={style.bottomContent}>
            <Components.AnchorTag id="get-a-quote" />
            <div className={style.getAQuote}>Schedule Your Free Estimate</div>
            <IKImage
              className={style.logo}
              path="/logo-alpine.png"
              alt="Southern Colorado Arkansas Valley Holiday Christmas Lights Service"
            />
            <Components.ContactForm className={style.form} />
          </div>
        </div>
      </div>
    </>
  );
};

Service.displayName = "Service";
export { Service };

const serviceMap = {
  roof: {
    title:
      "Roof and building accent lighting. Interior and Exterior LED string lighting.",
    subTitle: "Interior and Exterior LED String Lighting",
    miniTitle: "Roof and Building accent lighting",
    content: [
      "We size our C9 LED string lights to the exact length of your roofline. We can rig high multipitch roofs as well as customized accent features including doorway and balcony lights. All of our string lights are durable and rated for 10 amps as opposed to the standard 8 amps to be able to operate for many years in the altitude, wind and cold of our High Rockies winter. Our C9 LED bulbs are guaranteed for 40,000 hours of use and have a 3-season warranty! The clips we use to mount the lights to the house will come down at the end of every season and are harmless to shingles and gutters. We offer timers that control turning all of your lights on and off everyday totally effortless.",
    ],
    images: [
      "/products/salida-christmas-rope-lights.png",
      "/products/outdoor-lights-hanging.png",
      "/products/salida-christmas-lights-service.png",
      "/products/colorado-lights-hanging-business.png",
      "/products/multicolor-xmas-lights-colorado.png",
      "/logo-alpine.png",
    ],
  },
  fence: {
    title:
      "Fence and Rail Lighting. Interior and Exterior LED string lighting.",
    subTitle: "Interior and Exterior LED String Lighting",
    miniTitle: "Fence and Rail Lighting",
    content: [
      "We offer a multitude of LED bulb sizes and colors including large C9 bulbs, mini lights, and rope lights to line fences and railings. These can be used to tight-line, spiral or drape outdoor yard and patio fences as well as indoor railings and banisters. We will work with you to get the best style and colors for your home or business. All of our lights are rated and safe for both indoor and outdoor use. All of our string lights can be outfitted with a timer to make control stress free.",
    ],
    images: [
      "/products/salida-christmas-rope-lights.png",
      "/products/outdoor-lights-hanging.png",
      "/products/salida-christmas-lights-service.png",
      "/products/colorado-lights-hanging-business.png",
      "/products/multicolor-xmas-lights-colorado.png",
      "/logo-alpine.png",
    ],
  },
  tree: {
    title: "Tree and Bush Wraps. Interior and Exterior LED string lighting.",
    subTitle: "Interior and Exterior LED String Lighting",
    miniTitle: "Tree and Bush Wraps",
    content: [
      "We have a number of tree wrapping techniques to choose from ranging from tight trunk and limb wraps to drooping and draping tree lights. We use our energy star rated mini LED lights, which brilliantly illuminate trees and bushes using minimal power. We carry these lights in warm white and multicolor. We can quickly source strings in solid blue, red, green, orange, etc.",
    ],
    images: [
      "/products/salida-christmas-rope-lights.png",
      "/products/outdoor-lights-hanging.png",
      "/products/salida-christmas-lights-service.png",
      "/products/colorado-lights-hanging-business.png",
      "/products/multicolor-xmas-lights-colorado.png",
      "/logo-alpine.png",
    ],
  },
  patio: {
    title:
      "Patio and Outdoor Global Lighting. Interior and Exterior LED string lighting.",
    subTitle: "Interior and Exterior LED String Lighting",
    miniTitle: "Patio and Outdoor Global Lighting",
    content: [
      "Our S14 shatterproof LED string globe lights in both white and black wire are the perfect patio lighting for any home or business. Using posts and cables, we can rig our globe lights to fit any outdoor patio situation. Our LED rope lights also make an excellent ground and feature accent for any patio, porch, grill space or garden.",
    ],
    images: [
      "/products/salida-christmas-rope-lights.png",
      "/products/outdoor-lights-hanging.png",
      "/products/salida-christmas-lights-service.png",
      "/products/colorado-lights-hanging-business.png",
      "/products/multicolor-xmas-lights-colorado.png",
      "/logo-alpine.png",
    ],
  },
  ground: {
    title:
      "Ground Lighhting and Hanging Walkway Lighting. Interior and Exterior LED string lighting.",
    subTitle: "Interior and Exterior LED String Lighting",
    miniTitle: "Ground Lighhting and Hanging Walkway Lighting",
    content: [
      " Our PVC protected waterproof LED rope lights are an excellent option for outdoor deck, porch and walkway lighting. They also make a great indoor option for cabinets and hallways. We offer yard stands that elevate any of our LED string lights to beautifully illuminate outdoor walkways, plant beds, gardens, sheds and other features.",
    ],
    images: [
      "/products/salida-christmas-rope-lights.png",
      "/products/outdoor-lights-hanging.png",
      "/products/salida-christmas-lights-service.png",
      "/products/colorado-lights-hanging-business.png",
      "/products/multicolor-xmas-lights-colorado.png",
      "/logo-alpine.png",
    ],
  },
};
