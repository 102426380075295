import * as React from "react";
import * as style from "./Title.style";
import * as Components from "../../../components";
import { hooks } from "../../../utility";

const Title: React.FC = () => {
  const display = hooks.useDisplayContext();
  return (
    <>
      <div className={style.component}>
        <div className={style.overlay} />
        <div className={style.content}>
          <div className={style.title}>
            Make your home stand <br />
            out this winter
          </div>
          {display.isMobile ? (
            <div className={style.subTitle}>
              Full-service professional quality holiday, home, business, and
              special event lighting in and around Chaffee County, CO!
            </div>
          ) : (
            <div className={style.subTitle}>
              Full-service professional quality holiday, home,
              <br />
              business, and special event lighting in and around
              <br /> Chaffee County, CO!
            </div>
          )}
          <div className={style.buttons}>
            <div className={style.phoneButton}>
              <a href="tel:2529160516" className={style.link}>
                252-916-0516
              </a>
            </div>
            <Components.QuoteButton />
          </div>
        </div>
      </div>
    </>
  );
};

Title.displayName = "Title";
export { Title };
