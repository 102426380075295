import * as React from "react";
import * as style from "./Services.style";
import * as Components from "../../../components";
import { useSpring, animated, config } from "react-spring";
import { hooks } from "../../../utility";

const Services: React.FC = () => {
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const dataRef = hooks.useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  });
  const spring = useSpring({
    config: { ...config.slow, delay: 300 },
    from: { opacity: 0, right: "102%" },
    to: {
      opacity: dataRef?.isIntersecting ? 0.8 : 0,
      right: dataRef?.isIntersecting ? "-2%" : "102%",
    },
  });

  return (
    <div className={style.component}>
      <div className={style.title}>
        <div className={style.titleContent}>
          The <span className={style.best}>best</span> christmas lighting
          <br />
          services in <span className={style.chaffee}>chaffee county, co</span>
        </div>
        <div ref={triggerRef} />
        <animated.div style={spring} className={style.titleStripe} />
      </div>
      <div className={style.tiles}>
        <Components.ServiceTile.Roof className={style.tile} />
        <Components.ServiceTile.Fence className={style.tile} />
        <Components.ServiceTile.Tree className={style.tile} />
        <Components.ServiceTile.Patio className={style.tile} />
        <Components.ServiceTile.Ground className={style.tile} />
      </div>
    </div>
  );
};

Services.displayName = "Services";
export { Services };
