import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  textDecoration: "none",
  cursor: "pointer",
  position: "relative",
  height: 70,
  borderRadius: 2,
  display: "flex",
  alignItems: "center",
  padding: "0px 25px",
  fontFamily: Style.text.font.main,
  boxSizing: "border-box",
  border: `1px solid transparent`,
  transition: ".2s ease-in",
  fontSize: 18,
  color: Style.text.color.inverse,
  outline: "none",
  resize: "none",
  zIndex: 2,
  backgroundColor: Style.colors.translucent150,
  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
});

export const title = s({
  fontWeight: 500,
  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: 3,
});

export const dropDown = s({
  position: "absolute",
  zIndex: 5,
  top: 70,
  left: 0,
  right: 0,
  borderTop: `2px solid ${Style.colors.primary}`,
  backgroundColor: "#FCFCFC",
  border: `1px solid ${Style.colors.darkTranslucent650}`,
});

export const dropDownItem = s({
  color: Style.colors.second,
  textAlign: "left",
  padding: `20px 30px`,
  fontWeight: 600,
  letterSpacing: "1px",
  textDecoration: "none",
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      color: Style.colors.third,
      backgroundColor: Style.colors.darkTranslucent200,
    },
  },
});

export const label = s({
  color: Style.text.color.inverseSubtle,
  fontSize: 18,
  position: "absolute",
  left: 25,
  top: 20,
  zIndex: 1,
  transition: ".2s ease-in",
});

export const filledLabel = s({
  color: Style.text.color.inverseSubtle,
  position: "absolute",
  zIndex: 1,
  transition: ".2s ease-in",
  fontSize: 11,
  top: 6,
  left: 6,
});

export const arrow = s({
  position: "absolute",
  top: 20,
  right: 10,
});

export const error = s({
  paddingTop: 5,
  color: Style.colors.error,
  fontSize: Style.text.fontSize.xs,
  textAlign: "left",
});

export const hasError = s({
  borderColor: `${Style.colors.error} !important`,
});
