import * as React from "react";
import * as style from "./Imagination.style";
import * as Components from "../../../components";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../../utility";
import arkansasValley from "../../../../public/assets/arkansas-river-valley-christmas-lights.jpg";
import salidaXmasLights from "../../../../public/assets/salida-christmas-lights.jpg";

const Imagination: React.FC = () => {
  const display = hooks.useDisplayContext();
  return (
    <>
      <div className={style.component}>
        {!display.isMobile && (
          <div className={style.leftSection}>
            <img
              className={style.mainImage}
              src={arkansasValley}
              alt="chaffee county tree lighting"
            />
            <IKImage
              className={style.logo}
              alt="colorado arkansas valley christmas lights logo"
              path="/logo-night.png"
            />
            <img
              className={style.subImage}
              src={salidaXmasLights}
              alt="colorado chaffee christmas lights"
            />
          </div>
        )}
        <div className={style.rightSection}>
          <div className={style.preTitle}>
            Holiday lighting in and around Chaffee County, CO
          </div>
          <div className={style.title}>
            bring your imagination to life with our christmas lights!
          </div>
          <div className={style.details}>
            We are an upstart lighting company based in the Arkansas River
            Valley. Our company was founded in 2021 by two longtime local
            Arkansas Valley guides with backgrounds in the commercial lighting
            industry who are specialized in customer service for the last
            decade.
          </div>
          <Components.QuoteButton className={style.button} />
        </div>
      </div>
    </>
  );
};

Imagination.displayName = "Imagination";
export { Imagination };
