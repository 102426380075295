import * as React from "react";
import * as style from "./Services.style";
import * as Components from "../../components";
import { Title } from "../../components/Title";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";
import { useLocation } from "react-router-dom";

const Services: React.FC = () => {
  const display = hooks.useDisplayContext();
  const location = useLocation();
  React.useEffect(() => {
    const { hash } = location;
    var element = document.getElementById(hash.slice(1));
    if (!!element) {
      element.scrollIntoView();
    }
  }, [location]);
  const goToQuote = () => {
    var element = document.getElementById("get-a-quote");
    if (!!element) {
      element.scrollIntoView();
    }
  };
  return (
    <>
      <div className={style.component}>
        <Title title="Services" />
        <div className={style.bottomContent}>
          <div className={style.information}>
            <span>W</span>e offer full-service professional quality holiday,
            home, business and special event lighting in and around Chaffee
            County, CO. We provide customized exterior roof-line, tree and bush,
            patio and property lighting with full service installation,
            maintenance and take down. All of our lighting products are top of
            the line commercial grade products designed to last in our tough top
            of the Rockies climate.
          </div>
          <div className={style.information}>
            <span>F</span>ill out an inquiry on our website concerning your
            lighting project of interest. We will schedule a time to take some
            quick measurements and provide a free quote for your customized
            lighting setup and installation.
          </div>
          <div className={style.tiles}>
            <Components.ServiceTile.Roof
              className={style.tile}
              link="/service/roof"
            />
            <Components.ServiceTile.Fence
              className={style.tile}
              link="/service/fence"
            />
            <Components.ServiceTile.Tree
              className={style.tile}
              link="/service/tree"
            />
            <Components.ServiceTile.Patio
              className={style.tile}
              link="/service/patio"
            />
            <Components.ServiceTile.Ground
              className={style.tile}
              link="/service/ground"
            />
          </div>
        </div>
        <div className={style.component}>
          <Title title="Products We Use" className={style.subTitle} />
          <div className={style.productsSection}>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/salida-christmas-rope-lights.png"
                    alt="salida christmas rope lights"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/rope-lights-1.png"
                      alt="christmas lights hanging bv colorado"
                    />
                    <IKImage
                      path="/products/rope-lights-2.png"
                      alt="buena vista colorado rope lights lighting"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>LED Rope Lights</div>
                <div className={style.productDescription}>
                  We use our LED rope lights for tree and bush wraps as well as
                  for walkway and lawn accent lighting. Our LED lights use a
                  vertical bulb so that the LEDs point out of, rather than down,
                  the line of rope light -- resulting in a greater angle of
                  illumination that is more eye-catching and impressive. We keep
                  warm white, red and green in stock, and can custom order blue,
                  orange and yellow. These lights are custom cut to work for any
                  space.
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/colorado-lights-hanging-business.png"
                    alt="salida christmas rope lights"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/christmas-lights-hanging-bv-colorado.png"
                      alt="christmas lights hanging bv colorado"
                    />
                    <IKImage
                      path="/products/buena-vista-colorado-roof-lighting.png"
                      alt="buena vista colorado roof lighting"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>LED String Lights</div>
                <div className={style.productDescription}>
                  We offer best in class C9 LED lights in any custom combination
                  of Warm White, Pure White, Red, Amber, Blue, Green and
                  Multicolored Bulbs. We offer multiple bulb spacing options to
                  provide the best look and price for your home. These energy
                  efficient C9 LED light bulbs are beautiful, super bright,
                  versatile, and will shine for 50,000 hours. The reasoning is
                  to save you money; incandescent bulbs use significantly more
                  power than LED bulbs and are much more fragile than these
                  sturdy, multifaceted LED bulbs that do not break, chip, or
                  fade.
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/outdoor-lights-hanging.png"
                    alt="salid christmas rope lights"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/chaffee-county-christmas-lights.png"
                      alt="christmas lights hanging bv colorado"
                    />
                    <IKImage
                      path="/products/chaffee-county-xmas-lights.png"
                      alt="buena vista colorado roof lighting"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>
                  S14 Shatterproof LED String Lights
                </div>
                <div className={style.productDescription}>
                  We carry this product with bulbs in warm white on black and
                  white strings. Individual light strings are connectable and
                  consist of 24 hanging bulbs on 48’ strings. hese Warm White
                  LED S14 light strings have long-lasting 15,000 hour bulbs and
                  are on a heavy duty 16 gauge black wire stringer. Each socket
                  is made with PVC plastic to provide a water-tight seal and to
                  ensure no defects due to weather. These durable and heavy duty
                  light strings will provide you with years of maintenance free
                  use.
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/colorado-wreath-and-garland.png"
                    alt="salida wreath and garland"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/colorado-wreath.png"
                      alt="wreath hanging colorado"
                    />
                    <IKImage
                      path="/products/colorado-garland.png"
                      alt="buena vista colorado local garland"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>
                  Colorado Pine Wreath and Garland
                </div>
                <div className={style.productDescription}>
                  Constructed with heavy duty wire frames to ensure years of
                  use, we offer artificial Colorado Pine garland and wreaths. We
                  offer wreaths in multiple sizes and all garland is trimmed to
                  length and priced by the foot. We also offer garland and
                  wreaths with preinstalled warm white LEDs (100 bulbs in every
                  nine-foot section of garland).
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
            <div className={style.product}>
              {!display.isMobile && (
                <div className={style.productImages}>
                  <IKImage
                    className={style.mainProductImage}
                    path="/products/wide-angle-tree-lights.jpg"
                    alt="salid christmas rope lights"
                  />
                  <div className={style.supportingProductImages}>
                    <IKImage
                      path="/products/wide-angle-lights.jpg"
                      alt="christmas lights hanging bv colorado"
                    />
                    <IKImage
                      path="/products/wide-angle-mini-leds.jpg"
                      alt="buena vista colorado roof lighting"
                    />
                  </div>
                </div>
              )}
              <div className={style.productInfo}>
                <div className={style.productTitle}>
                  Wide Angle Mini-LED Lights
                </div>
                <div className={style.productDescription}>
                  Our mini-LED lights are ideal for tree wraps, bushes, and look
                  great on railings and balconies. These heavy-duty LED
                  Christmas light sets use a molded high-quality one-piece LED
                  bulb construction so the bulbs cannot be pulled out or break,
                  providing years of maintenance free use. Each LED uses a
                  patented concave design making these LEDs viewable from all
                  angles. Our strings contain 100 LED lights per 50’ of wire. We
                  carry warm white and multi-color. Energy Star Certified and UL
                  listed for indoor and outdoor use
                </div>
              </div>
              <div onClick={goToQuote} className={style.productQuoteLink}>
                Schedule an estimate now! &gt;
              </div>
            </div>
          </div>
          <div className={style.bottomContent}>
            <Components.AnchorTag id="get-a-quote" />
            <div className={style.getAQuote}>Schedule Your Free Estimate</div>
            <IKImage
              className={style.logo}
              path="/logo-alpine.png"
              alt="Southern Colorado Arkansas Valley Holiday Christmas Lights Service"
            />
            <Components.ContactForm className={style.form} />
          </div>
        </div>
      </div>
    </>
  );
};

Services.displayName = "Services";
export { Services };
