import * as React from "react";
import * as style from "./About.style";
import * as Components from "../../components";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";
import { useLocation } from "react-router-dom";

const About: React.FC = () => {
  const display = hooks.useDisplayContext();
  const location = useLocation();
  React.useEffect(() => {
    const { hash } = location;
    var element = document.getElementById(hash.slice(1));
    if (!!element) {
      element.scrollIntoView();
    }
  }, [location]);
  return (
    <>
      <div className={style.component}>
        <Components.Title title="About Us" />
        <div className={style.bottomContent}>
          <div className={style.information}>
            We are a new lighting company in Chaffee County, CO that focuses on
            holiday, building accent, tree and patio lighting for both
            residential homes and businesses. We are based in Poncha Springs and
            our work focuses in and around the Arkansas River Valley. Chaffee
            County Lights LLC was founded in 2021 by Drew Hoag and Michael
            Aiello, two local Arkansas Valley guides. Michael and I are both
            incredibly fortunate to have been members of the Arkansas Valley
            community for five years now, and we are excited to contribute a
            quality—and festive—service to our valley. All of our lights are top
            of the line 10-amp shatterproof LED strings lights, which are built
            to handle our tough high-country climate. We offer a multitude of
            products and colors in stock and can quickly source an even larger
            inventory from our distributor in Colorado Springs. Set up a free
            consultation below to schedule an in-person visit to measure and
            customize your lighting set up!
          </div>
          <Components.AnchorTag id="get-a-quote" />
          <div className={style.getAQuote}>Schedule Your Free Estimate</div>
          <IKImage
            className={style.logo}
            path="/logo-alpine.png"
            alt="Southern Colorado Arkansas Valley Holiday Christmas Lights Service"
          />
          <Components.ContactForm className={style.form} />
        </div>
      </div>
    </>
  );
};

About.displayName = "About";
export { About };
