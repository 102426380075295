import * as React from "react";
import * as style from "./ContactForm.style";
import { Button, TextInput } from "../../components";
import { hooks } from "../../utility";
import { classes } from "typestyle";
import emailjs from "emailjs-com";
import * as ServiceTile from "../ServiceTile";
import { toast } from "react-toastify";

const SERVICE_ID = "service_c8mq82g";
const TEMPLATE_ID = "template_e8jjqb8";
const USER_ID = "user_BYHTvWHdurWUyvjL7ZRHK";
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

interface ComponentProps {
  className?: string;
  onSuccess?: () => void;
}

const ContactForm: React.FC<ComponentProps> = ({ className, onSuccess }) => {
  const display = hooks.useDisplayContext();

  const [name, setName] = React.useState<string | undefined>();
  const [email, setEmail] = React.useState<string | undefined>();
  const [mobile, setMobile] = React.useState<string | undefined>();
  const [address, setAddress] = React.useState<string | undefined>();
  const [message, setMessage] = React.useState<string | undefined>();
  const [selectedServices, setSelectedServices] = React.useState<string[]>([]);

  const [nameError, setNameError] = React.useState<string | undefined>();
  const [emailError, setEmailError] = React.useState<string | undefined>();
  const [mobileError, setMobileError] = React.useState<string | undefined>();
  const [addressError, setAddressError] = React.useState<string | undefined>();
  const [messageError, setMessageError] = React.useState<string | undefined>();

  const [loading, setLoading] = React.useState(false);

  const onServiceClick = (service: string) => {
    const newSelectedServices = [...selectedServices];
    const index = newSelectedServices.indexOf(service);
    if (index !== -1) {
      newSelectedServices.splice(index, 1);
    } else {
      newSelectedServices.push(service);
    }
    setSelectedServices(newSelectedServices);
  };

  const onSubmit = () => {
    if (!name) {
      setNameError("Please enter your name");
    }
    if (!email) {
      setEmailError("Please enter your email");
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
    }
    if (mobile.length < 10) {
      setMobileError("Please provide a valid mobile number");
    }
    if (!address) {
      setAddressError("Please provide a valid address");
    }
    if (!message) {
      setMessageError("Please enter a message");
    }
    if (
      !!name &&
      !!email &&
      emailRegex.test(email) &&
      mobile.length === 10 &&
      !!address &&
      !!message
    ) {
      setLoading(true);
      emailjs
        .send(
          SERVICE_ID,
          TEMPLATE_ID,
          {
            name,
            email,
            mobile,
            address,
            services: selectedServices.toString(),
            message,
          },
          USER_ID,
        )
        .then(
          (response) => {
            toast.success(
              "Thank you for reaching out! We will get back to you shortly.",
              { className: style.toast },
            );
            setName("");
            setMobile("");
            setEmail("");
            setMessage("");
            setAddress("");
            setLoading(false);
            if (onSuccess) {
              onSuccess();
            }
          },
          (error) => {
            toast.warning(
              "Something seems to have gone wrong - please refresh the page and try again",
            );
            setLoading(false);
          },
        )
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={classes(style.component, className)}>
      <div className={style.content}>
        <div className={style.information}>
          Fill out the form below or{" "}
          <a
            className={style.link}
            target="_blank"
            href="https://calendly.com/inquiries-46/estimate?month=2022-11"
          >
            book with our calendly
          </a>
          .
        </div>
        <div className={style.inputSection}>
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.input}
            label="Name"
            value={name}
            onChange={(val?: string) => {
              setName(val);
              if (!val) {
                setNameError("Please enter your name");
              } else {
                setNameError("");
              }
            }}
            onBlur={() => {
              if (!name) {
                setNameError("Please enter your name");
              }
            }}
            error={nameError}
          />
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.input}
            label="Email"
            value={email}
            onChange={(val: string) => {
              setEmail(val);
              if (!val) {
                setEmailError("Please enter your email");
              } else {
                setEmailError("");
              }
            }}
            onBlur={() => {
              if (!email) {
                setEmailError("Please enter your email");
              } else if (!emailRegex.test(email)) {
                setEmailError("Please enter a valid email");
              }
            }}
            inputType="email"
            error={emailError}
          />
        </div>
        <div className={style.inputSection}>
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.input}
            label="Mobile"
            value={mobile}
            onChange={(val: string) => {
              if (/^[0-9]*$/.test(val) && val.length < 11) {
                setMobile(val);
                setMobileError("");
              }
            }}
            onBlur={() => {
              if (mobile.length < 10) {
                setMobileError(
                  "Please provide a 10-digit mobile number, or leave this field blank",
                );
              }
            }}
            inputType="tel"
            error={mobileError}
          />
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.input}
            label="Address"
            value={address}
            onChange={(val: string) => setAddress(val)}
            onBlur={() => {
              if (!address) {
                setAddressError("Please provide a valid address");
              }
            }}
            error={addressError}
          />
        </div>
      </div>
      <div className={style.title}>Choose from the following services</div>
      <div className={style.tiles}>
        <ServiceTile.Roof
          onClick={() => onServiceClick("roof")}
          className={classes(
            style.tile,
            selectedServices.includes("roof") ? style.selected : "",
          )}
        />
        <ServiceTile.Fence
          onClick={() => onServiceClick("fence")}
          className={classes(
            style.tile,
            selectedServices.includes("fence") ? style.selected : "",
          )}
        />
        <ServiceTile.Tree
          onClick={() => onServiceClick("tree")}
          className={classes(
            style.tile,
            selectedServices.includes("tree") ? style.selected : "",
          )}
        />
        <ServiceTile.Patio
          onClick={() => onServiceClick("patio")}
          className={classes(
            style.tile,
            selectedServices.includes("patio") ? style.selected : "",
          )}
        />
        <ServiceTile.Ground
          onClick={() => onServiceClick("ground")}
          className={classes(
            style.tile,
            selectedServices.includes("ground") ? style.selected : "",
          )}
        />
      </div>
      <div className={style.content}>
        <div className={style.inputSection}>
          <TextInput
            wrapperClassName={style.inputWrapper}
            className={style.messageInput}
            label="Additional Details"
            value={message}
            onChange={(val: string) => {
              setMessage(val);
              if (!val) {
                setMessageError(
                  "Please enter some information about why you are reaching out",
                );
              } else {
                setMessageError("");
              }
            }}
            onBlur={() => {
              if (!message) {
                setMessageError(
                  "Please enter some information about why you are reaching out",
                );
              }
            }}
            error={messageError}
            isTextArea={true}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            className={style.button}
            rounded={false}
            disabled={
              !!nameError || !!emailError || !!mobileError || !!messageError
            }
            loading={loading}
            onClick={onSubmit}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

ContactForm.displayName = "ContactForm";
export { ContactForm };
