import * as React from "react";
import * as style from "./ServiceTile.style";
import { classes } from "typestyle";
import { Link } from "../Link";
import { useNavigate } from "react-router-dom";

interface ComponentProps {
  className?: string;
  link?: string;
  onClick?: () => void;
}

export const Roof: React.FC<ComponentProps> = ({
  className,
  link,
  onClick,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={!!link ? () => navigate(link) : onClick}
      className={classes(style.roof, style.shared, className)}
    >
      <div className={style.overlay} />
      <div className={style.content}>Roof and building accent lighting</div>
    </div>
  );
};

export const Fence: React.FC<ComponentProps> = ({
  className,
  link,
  onClick,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={!!link ? () => navigate(link) : onClick}
      className={classes(style.fence, style.shared, className)}
    >
      <div className={style.overlay} />
      <div className={style.content}>Fence and Rail Lighting</div>
    </div>
  );
};

export const Tree: React.FC<ComponentProps> = ({
  className,
  link,
  onClick,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={!!link ? () => navigate(link) : onClick}
      className={classes(style.tree, style.shared, className)}
    >
      <div className={style.overlay} />
      <div className={style.content}>Tree and Bush Wraps</div>
    </div>
  );
};

export const Patio: React.FC<ComponentProps> = ({
  className,
  link,
  onClick,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={!!link ? () => navigate(link) : onClick}
      className={classes(style.patio, style.shared, className)}
    >
      <div className={style.overlay} />
      <div className={style.content}>Patio and outdoor globe lighting</div>
    </div>
  );
};

export const Ground: React.FC<ComponentProps> = ({
  className,
  link,
  onClick,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={!!link ? () => navigate(link) : onClick}
      className={classes(style.ground, style.shared, className)}
    >
      <div className={style.overlay} />
      <div className={style.content}>
        Ground Lighting and hanging walkway lighting
      </div>
    </div>
  );
};
