import * as React from "react";
import * as style from "./Title.style";
import { hooks } from "../../utility";
import { classes } from "typestyle";

const Title: React.FC<{ className?: string; title: string }> = ({
  className,
  title,
}) => {
  const display = hooks.useDisplayContext();
  return (
    <>
      <div className={classes(className, style.component)}>
        <div className={style.overlay} />
        <div className={style.content}>
          <div className={style.title}>{title}</div>
        </div>
      </div>
    </>
  );
};

Title.displayName = "Title";
export { Title };
