import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  backgroundColor: "#F3F3F3",
  width: "100%",
  fontFamily: Style.text.font.main,
});

export const titleComponent = s(
  {
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/CCL/colorado-home-christmas-lights-title.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    fontFamily: Style.text.font.main,
    padding: "30px 20px",
    position: "relative",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      padding: "92px 64px 46px",
    },
  ),
);

export const overlay = s({
  position: "absolute",
  inset: "0 0 0 0",
  zIndex: 2,
  backgroundColor: Style.colors.darkTranslucent500,
});

export const content = s(
  {
    zIndex: 3,
    position: "relative",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      paddingLeft: 300,
      display: "flex",
      alignItems: "center",
    },
  ),
);

export const title = s(
  {
    fontWeight: 800,
    color: "#F9F9F9",
    textTransform: "uppercase",
    fontSize: 32,
    marginRight: 15,
    textAlign: "center",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      fontSize: 44,
    },
  ),
);

export const subTitle = s(
  {
    fontWeight: 400,
    color: "#F9F9F9",
    textTransform: "uppercase",
    fontSize: 20,
    lineHeight: "28px",
    textAlign: "center",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      textAlign: "left",
      fontSize: 36,
      lineHeight: "40px",
    },
  ),
);

export const bottomContent = s({
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/CCL/southern-colorado-holiday-lighting.png)",
  backgroundSize: "cover",
  padding: "20px",
  textAlign: "center",
  position: "relative",
});

export const information = s(
  {
    fontSize: 16,
    lineHeight: "20px",
    color: "#434343",
    fontFamily: Style.text.font.second,
    marginBottom: 30,
    width: "90%",
    textAlign: "left",
    margin: "auto",
    $nest: {
      span: {
        fontSize: 24,
        fontWeight: 600,
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 26,
      marginBottom: 60,
      lineHeight: "40px",
      width: "80%",
      maxWidth: 1200,
      $nest: {
        span: {
          fontSize: 40,
        },
      },
    },
  ),
);

export const getAQuote = s({
  fontSize: 40,
  fontWeight: 700,
  fontFamily: Style.text.font.main,
  color: "#171717",
  textTransform: "uppercase",
  marginBottom: 30,
});

export const logo = s(
  {
    width: "70%",
    margin: "auto",
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "20%",
    },
  ),
);

export const form = s({
  padding: 0,
});

export const tiles = s(
  {
    padding: "2vw",
    marginBottom: 60,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      width: "80%",
      margin: "auto",
    },
  ),
);

export const tile = s(
  {
    width: "90%",
    margin: "0px auto 10px",
    minHeight: 130,
    fontFamily: Style.text.font.main,
    fontWeight: 500,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "16vw",
      minHeight: "18vw",
      margin: "none",
    },
  ),
);

export const productsSection = s(
  {
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/CCL/buena-vista-colorado-xmas-lighting.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "10px 20px",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      padding: "60px 50px",
    },
  ),
);

export const product = s(
  {
    backgroundColor: "#F8F8F8",
    padding: "25px 10px",
    position: "relative",
    marginBottom: 20,
    textAlign: "left",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      padding: "45px 40px",
      marginBottom: 45,
      display: "flex",
    },
  ),
);

export const serviceProductImages = s({
  flex: 3,
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  $nest: {
    img: {
      width: "calc(50% - 10px)",
      marginBottom: 20,
    },
  },
});

export const productImages = s({
  flex: 3,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const mainProductImage = s({
  flex: 3,
  marginRight: 10,
  maxWidth: "70%",
});

export const supportingProductImages = s({
  flex: 1,
  textAlign: "right",
  $nest: {
    img: {
      flex: 1,
      maxWidth: "100%",
      $nest: {
        "&:first-child": {
          marginBottom: 10,
          height: "45%",
        },
      },
    },
  },
});
export const productInfo = s(
  {
    flex: 7,
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      paddingLeft: 50,
    },
  ),
);

export const productTitle = s(
  {
    fontFamily: Style.text.font.main,
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "30px",
    marginBottom: 10,
    color: "#3E3E3E",
    textTransform: "uppercase",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      fontSize: 36,
      lineHeight: "42px",
    },
  ),
);

export const productSubtitle = s({
  fontSize: 12,
  fontWeight: 700,
  color: "#898989",
  marginBottom: 20,
});

export const productDescription = s(
  {
    fontFamily: Style.text.font.second,
    fontSize: 16,
    lineHeight: "24px",
    color: "#434343",
    marginBottom: 10,
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      fontSize: 16,
      lineHeight: "24px",
    },
  ),
);

export const productQuoteLink = s(
  {
    color: "#4C53FB",
    fontSize: 16,
    cursor: "pointer",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      position: "absolute",
      bottom: 20,
      right: 20,
    },
  ),
);

export const pageSubTitle = s(
  {
    position: "relative",
    $nest: {
      div: {
        fontSize: 20,
      },
    },
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      padding: "26px 64px",
      $nest: {
        div: {
          fontSize: 28,
        },
      },
    },
  ),
);
