import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/CCL/colorado-home-christmas-lights-title.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    fontFamily: Style.text.font.main,
    padding: "30px 20px",
    position: "relative",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      padding: "92px 64px 46px",
    },
  ),
);

export const overlay = s({
  position: "absolute",
  inset: "0 0 0 0",
  zIndex: 2,
  backgroundColor: Style.colors.darkTranslucent500,
});

export const content = s({
  zIndex: 3,
  position: "relative",
});

export const title = s(
  {
    fontWeight: 800,
    color: "#DBDBDB",
    textTransform: "uppercase",
    fontSize: 32,
    textAlign: "center",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      fontSize: 64,
    },
  ),
);
