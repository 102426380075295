import * as React from "react";
import * as style from "./Footer.style";
import { IKImage } from "imagekitio-react";
import { classes } from "typestyle";
import { Link } from "../Link";
import { hooks } from "../../utility";
import { QuoteButton } from "../QuoteButton";

export interface ComponentProps {
  className?: string;
}

const Footer: React.FC<ComponentProps> = ({ className }) => {
  const display = hooks.useDisplayContext();
  return (
    <div className={classes(className, style.component)}>
      <div className={style.leftSection}>
        <div className={style.links}>
          <Link to={`/`}>
            <div>Home</div>
          </Link>
          <Link to={`/about`}>
            <div>About</div>
          </Link>
          <Link to={`/services`}>
            <div>Services</div>
          </Link>
          <Link to={`/contact`}>
            <div>Contact</div>
          </Link>
        </div>
        {!display.isMobile && <div className={style.triangle} />}
        {!display.isMobile && (
          <div className={style.services}>
            <div>Roof and Building Accent Lighting</div>
            <div>Fence and Rail Lighting</div>
            <div>Tree and Bush Wraps</div>
            <div>Patio and Outdoor Globe Lighting</div>
            <div>Ground Lighting</div>
          </div>
        )}
      </div>
      {!display.isMobile && (
        <div className={style.lightsSection}>
          <IKImage
            alt="Chaffee County Home Christmas Lights"
            path="/lights-yellow-blue.png"
            className={style.lights}
          />
        </div>
      )}
      <div className={style.rightSection}>
        <div className={style.information}>
          <div>252-916-0516</div>
          <div>238 Way</div>
          <div>Chaffee County, Colorado</div>
          <div style={{ marginBottom: 15 }}>199112</div>
          {!display.isMobile && <QuoteButton />}
        </div>
        <IKImage
          alt="Chaffee County Home Christmas Lights Logo"
          path="/logo-night.png"
          className={style.logo}
        />
      </div>
    </div>
  );
};

Footer.displayName = "Footer";
export { Footer };
