import * as React from "react";
import { Link } from "../Link";
import { classes } from "typestyle";
import * as style from "./Header.style";
import { MobileHeader } from "./MobileHeader";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";
import { QuoteButton } from "../QuoteButton";
import { useLocation } from "react-router-dom";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
}

/**
 * Header component
 * Page Header
 */
const Header: React.FC<ComponentProps> = ({ className }) => {
  const display = hooks.useDisplayContext();
  const { pathname } = useLocation();

  return display.isMobile ? (
    <MobileHeader />
  ) : (
    <div className={classes(className, style.component)}>
      <div className={style.logoSection}>
        <Link to="/">
          <IKImage
            alt="Chaffee County Lights Logo"
            path="/logo-night.png"
            height={165}
            className={style.logo}
          />
        </Link>
      </div>
      <div className={style.linkSection}>
        <div className={pathname === "/" ? style.selectedLink : style.link}>
          <Link to={`/`}>
            <div>Home</div>
          </Link>
        </div>
        <div
          className={pathname === "/about" ? style.selectedLink : style.link}
        >
          <Link to={`/about`}>
            <div>About</div>
          </Link>
        </div>
        <div
          className={
            pathname.includes("service") ? style.selectedLink : style.link
          }
        >
          <Link to={`/services`}>
            <div>Services</div>
          </Link>
        </div>
        <div
          className={pathname === "/contact" ? style.selectedLink : style.link}
        >
          <Link to={`/contact`}>
            <div>Contact</div>
          </Link>
        </div>
      </div>
      <div className={style.quoteButtonSection}>
        <QuoteButton className={style.quoteButton} />
      </div>
    </div>
  );
};

Header.displayName = "Header";
export { Header };
