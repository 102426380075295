// prettier-ignore
import * as React from "react";

import {
  Route,
  BrowserRouter,
  useLocation,
  Routes,
  Navigate,
} from "react-router-dom";
import { Header, Footer } from "../components";
import { IKContext } from "imagekitio-react";
import { DisplayContextProvider } from "./DisplayContext";
import { useSpring, animated } from "react-spring";
import { media, style as s } from "typestyle";
// import "../../favicon.ico";
import { ToastContainer } from "react-toastify";
import { Home, About, Services, Service } from "../pages";
import "react-toastify/dist/ReactToastify.css";
import { screensize } from "../styles";

const component = s(
  {
    width: "100%",
    height: "100%",
    zIndex: 1,
    overflow: "hidden",
    backgroundColor: "#F3F3F3",
    paddingTop: 83,
  },
  media(
    { maxWidth: screensize.smMax },
    {
      paddingTop: 75,
    },
  ),
);

const Page: React.FC = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className={component}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service/:service" element={<Service />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
};

export const App = () => {
  const spring = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  return (
    <animated.div style={{ ...spring, position: "relative" }}>
      <BrowserRouter>
        <DisplayContextProvider>
          <IKContext urlEndpoint="https://ik.imagekit.io/yd113v8urnw4/CCL">
            <Header />
            <Page />
            <Footer />
          </IKContext>
        </DisplayContextProvider>
        <ToastContainer
          autoClose={5000}
          position="top-right"
          newestOnTop={false}
          hideProgressBar={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </BrowserRouter>
    </animated.div>
  );
};
