import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  backgroundColor: "#F3F3F3",
  width: "100%",
});

export const bottomContent = s({
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/CCL/southern-colorado-holiday-lighting.png)",
  backgroundSize: "cover",
  padding: "20px",
  textAlign: "center",
  position: "relative",
});

export const information = s(
  {
    fontSize: 16,
    lineHeight: "20px",
    color: "#434343",
    fontFamily: Style.text.font.second,
    marginBottom: 30,
    width: "90%",
    fontWeight: 500,
    margin: "auto",
    textAlign: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 26,
      marginBottom: 60,
      lineHeight: "36px",
      width: "80%",
      maxWidth: 1200,
      textAlign: "left",
    },
  ),
);

export const getAQuote = s({
  fontSize: 40,
  fontWeight: 700,
  fontFamily: Style.text.font.main,
  color: "#171717",
  textTransform: "uppercase",
  marginBottom: 30,
});

export const logo = s(
  {
    width: "70%",
    margin: "auto",
    marginBottom: 30,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "20%",
    },
  ),
);

export const form = s({
  padding: 0,
});
