import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    position: "relative",
    backgroundColor: "#F9F9F9",
    fontFamily: Style.text.font.main,
    zIndex: 20,
    padding: "25px 50px",
    display: "flex",
    justifyContent: "space-between",
  },
  media({ minWidth: Style.screensize.mdMin }, {}),
);

export const leftSection = s(
  {},
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: "0 0 40%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  ),
);

export const lightsSection = s({
  flex: "0 0 20%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
});

export const rightSection = s(
  { textAlign: "right" },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: "0 0 40%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      textAlign: "center",
    },
  ),
);

export const links = s({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "24px",
  textTransform: "uppercase",
  color: Style.text.color.main,
  marginRight: 10,
  $nest: {
    a: {
      color: "inherit",
      textDecoration: "none",
    },
    div: {
      transition: ".2s ease-in",
      marginBottom: 10,
      $nest: {
        "&:hover": {
          cursor: "pointer",
          color: Style.text.color.subtle,
        },
      },
    },
  },
});

export const triangle = s({
  width: 0,
  height: 0,
  borderTop: "75px solid transparent",
  borderBottom: "75px solid transparent",
  borderRight: "150px solid #202020",
});

export const services = s({
  padding: "0px 25px 0px 10px",
  backgroundColor: "#202020",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  color: "#F4F4F4",
  fontSize: ".7em",
  fontWeight: 500,
  minHeight: 150,
  maxHeight: 150,
  $nest: {
    a: {
      color: "inherit",
      textDecoration: "none",
    },
    div: {
      transition: ".2s ease-in",
      marginBottom: 5,
      $nest: {
        "&:hover": {
          cursor: "pointer",
          color: Style.text.color.inverseSubtle,
        },
      },
    },
  },
});

export const lights = s({
  width: "100%",
});

export const information = s({
  fontSize: 14,
  lineHeight: "18px",
  fontWeight: 500,
  color: Style.text.color.main,
  marginRight: 20,
});

export const logo = s(
  {
    width: "80%",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "10vw",
    },
  ),
);
