import { media, style as s } from "typestyle";
import * as Style from "../../styles";
import { title } from "../NavDropdown/NavDropdown.style";

export const component = s({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  background: `#F9F9F9`,
  zIndex: 10,
  padding: "18px 50px",
  display: "flex",
  justifyContent: "space-between",
  fontFamily: Style.text.font.main,
  height: 83,
  maxHeight: 83,
  textTransform: "uppercase",
});

export const logoSection = s({
  flex: "0 0 30%",
});

export const logo = s({
  height: 165,
});

export const linkSection = s({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: 500,
  // padding: `0px 12px`,
  fontSize: 16,
  color: Style.text.color.main,
  maxWidth: "40%",
});

export const link = s({
  $nest: {
    a: {
      color: "inherit",
      textDecoration: "none",
    },
    div: {
      transition: ".2s ease-in",
      $nest: {
        "&:hover": {
          cursor: "pointer",
          color: Style.text.color.subtle,
        },
      },
    },
  },
});

export const selectedLink = s({
  color: Style.text.color.inverse,
  background:
    "linear-gradient(180deg, #7FCCF8 47.92%, rgba(119, 184, 221, 0.96) 72.92%), linear-gradient(180deg, rgba(113, 175, 210, 0.974925) 46.11%, #7FCCF8 55.67%, rgba(110, 171, 205, 0.96) 89.85%)",
  boxShadow: "inset 4px 4px 20px 5px rgba(0, 0, 0, 0.1)",
  borderRadius: 4,
  padding: "16px 24px",
  fontWeight: 600,
  transition: ".2s ease-in",
  $nest: {
    a: {
      color: "inherit",
      textDecoration: "none",
    },
    "&:hover": {
      cursor: "pointer",
      color: "#E0E0E0",
    },
  },
});

export const quoteButtonSection = s({
  // flex: 1,
  textAlign: "right",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const quoteButton = s({
  whiteSpace: "nowrap",
});
