import * as React from "react";
import * as style from "./Home.style";
import * as Components from "../../components";
import { Title } from "./Title";
import { Services } from "./Services";
import { Imagination } from "./Imagination";
import { Process } from "./Process";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../utility";

const Home: React.FC = () => {
  const display = hooks.useDisplayContext();
  return (
    <>
      <div className={style.component}>
        <Title />
        <Services />
        <Imagination />
        <Process />
        <div className={style.bottomContent}>
          {!display.isMobile && (
            <div className={style.information}>
              We offer full-service professional quality holiday, home, business
              and special event lighting in and around Chaffee County, CO. We
              provide customized exterior roof-line, tree and bush, patio and
              property lighting with full service installation, maintenance and
              take down. All of our lighting products are top of the line
              commercial grade products designed to last in our tough top of the
              Rockies climate.
              <br />
              Fill out an inquiry on our website concerning your lighting
              project of interest. We will schedule a time to take some quick
              measurements and provide a free quote for your customized lighting
              setup and installation.
            </div>
          )}
          <Components.AnchorTag id="get-a-quote" />
          <div className={style.getAQuote}>Schedule Your Free Estimate</div>
          <IKImage
            className={style.logo}
            path="/logo-alpine.png"
            alt="Southern Colorado Arkansas Valley Holiday Christmas Lights Service"
          />
          <Components.ContactForm className={style.form} />
        </div>
      </div>
    </>
  );
};

Home.displayName = "Home";
export { Home };
