import * as React from "react";
import * as style from "./QuoteButton.style";
import { classes } from "typestyle";
import { Link } from "react-router-dom";
interface ComponentProps {
  className?: string;
}

const QuoteButton: React.FC<ComponentProps> = ({ className }) => {
  const goToQuote = () => {
    var element = document.getElementById("get-a-quote");
    if (!!element) {
      element.scrollIntoView();
    }
  };
  return (
    <div className={style.link} onClick={goToQuote}>
      <div className={classes(style.component, className)}>
        Schedule an estimate
      </div>
    </div>
  );
};

QuoteButton.displayName = "QuoteButton";
export { QuoteButton };
