import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    padding: "20px 15px",
    fontFamily: Style.text.font.second,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      padding: 0,
    },
  ),
);

export const content = s({
  margin: "auto",
  maxWidth: 815,
  textAlign: "center",
  marginBottom: 30,
});

export const subTitle = s({
  fontWeight: 700,
  fontFamily: Style.text.font.second,
  color: "#7B9DB7",
  textTransform: "uppercase",
  marginBottom: 20,
  letterSpacing: "2px",
  fontSize: 14,
});

export const title = s(
  {
    fontWeight: 700,
    color: "#434343",
    fontFamily: Style.text.font.main,
    fontSize: Style.text.fontSize.medium,
    lineHeight: "36px",
    marginBottom: 15,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: Style.text.fontSize.large,
      lineHeight: "48px",
    },
  ),
);

export const inputSection = s(
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
    },
  ),
);

export const inputWrapper = s(
  {
    marginBottom: 15,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      flex: 1,
      marginRight: 15,
      $nest: {
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
  ),
);

export const input = s({
  width: "100%",
});

export const messageInput = s(
  {
    width: "100%",
    height: `150px !important`,
    paddingTop: `25px !important`,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      height: `250px !important`,
    },
  ),
);

export const button = s({
  margin: "20px auto",
  fontSize: 18,
  fontWeight: 600,
  fontFamily: Style.text.font.second,
});

export const toast = s({
  padding: `20px 30px`,
  fontSize: Style.text.fontSize.medium,
  maxWidth: "auto",
  fontFamily: Style.text.font.main,
});

export const services = s({
  display: "flex",
  justifyContent: "space-around",
});

export const tiles = s(
  {
    marginBottom: 15,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 30,
      alignItems: "center",
    },
  ),
);

export const tile = s(
  {
    width: "100%",
    fontWeight: 400,
    borderRadius: 4,
    margin: "0px auto 10px",
    boxSizing: "border-box",
    $nest: {
      div: {
        fontSize: 16,
        lineHeight: "18px",
      },
    },
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      minWidth: "18vw",
      width: "18vw",
      minHeight: "16vw",
      margin: "none",
      $nest: {
        div: { fontSize: "inherit", lineHeight: "inherit" },
      },
    },
  ),
);

export const selected = s({
  border: `4px solid ${Style.colors.green}`,
});

export const information = s({
  fontWeight: 700,
  fontFamily: Style.text.font.second,
  color: "#434343",
  marginBottom: 20,
  letterSpacing: "2px",
  fontSize: 22,
});

export const link = s({
  color: "#434343",
});
