import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    padding: "30px 20px",
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/CCL/southern-colorado-holiday-lighting.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    fontFamily: Style.text.font.main,
    justifyContent: "space-between",
    textAlign: "center",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "100%",
      backgroundImage:
        "url(https://ik.imagekit.io/yd113v8urnw4/CCL/chaffee-county-holiday-lighting-service.png)",
      padding: "82px 0px",
      display: "flex",
      textAlign: "left",
    },
  ),
);

export const leftSection = s({
  flex: "0 0 45%",
  position: "relative",
  paddingLeft: "2vw",
});

export const mainImage = s({
  width: "80%",
  marginBottom: "2vw",
});

export const logo = s({
  width: "40%",
});

export const subImage = s({
  position: "absolute",
  left: "calc(40% + 2vw)",
  top: "20vw",
  width: "calc(60% - 4vw)",
});

export const rightSection = s(
  {
    flex: "0 0 45%",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      paddingRight: 60,
    },
  ),
);

export const preTitle = s(
  {
    fontFamily: Style.text.font.second,
    fontSize: 18,
    lineHeight: "24px",
    marginBottom: 20,
    color: "#434343",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 24,
      lineHeight: "36px",
    },
  ),
);

export const title = s(
  {
    fontSize: 32,
    lineHeight: "36px",
    color: "#171717",
    marginBottom: 30,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 40,
      lineHeight: "48px",
    },
  ),
);

export const details = s(
  {
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: Style.text.font.second,
    color: "#434343",
    marginBottom: 75,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 18,
      lineHeight: "27px",
    },
  ),
);

export const button = s(
  {
    width: "auto",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "fit-content",
    },
  ),
);
