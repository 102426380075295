import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    width: "100%",
    padding: "30px 20px",
    display: "flex",
    fontFamily: Style.text.font.main,
    background: "#F9F9F9",
    backgroundImage:
      "url(https://ik.imagekit.io/yd113v8urnw4/CCL/xmas-tree-lighting-colorado.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      backgroundImage: "none",
      padding: "60px 0px",
    },
  ),
);

export const overlay = s({
  position: "absolute",
  inset: "0 0 0 0",
  zIndex: 2,
  backgroundColor: Style.colors.translucent650,
});

export const rightSection = s({
  flex: "0 0 40%",
  textAlign: "right",
  position: "relative",
  paddingRight: "2vw",
});

export const mainImage = s({
  width: "80%",
  aspectRatio: "3/2",
  objectFit: "cover",
  marginBottom: "1vw",
});

export const logo = s({
  width: "40%",
  marginBottom: 60,
});

export const subImage = s({
  position: "absolute",
  right: "calc(40% + 2vw)",
  top: "17vw",
  width: "calc(60% - 4vw)",
  aspectRatio: "3/2",
  objectFit: "cover",
});

export const leftSection = s(
  {
    flex: 1,
    position: "relative",
    zIndex: 3,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      paddingLeft: 60,
    },
  ),
);

export const title = s(
  {
    fontFamily: Style.text.font.second,
    fontWeight: 500,
    fontSize: 24,
    marginBottom: 30,
    color: "#434343",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 32,
      marginBottom: 40,
    },
  ),
);

export const item = s({
  display: "flex",
  marginBottom: 30,
});

export const number = s(
  {
    display: "none",
    borderRadius: "50%",
    width: 44,
    height: 36,
    padding: 8,
    border: "3px solid #434343",
    textAlign: "center",
    fontSize: 32,
    color: "#434343",
    background: "transparent",
    marginRight: 20,
    fontWeight: 600,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "block",
    },
  ),
);

export const itemTitle = s(
  {
    color: "#171717",
    fontFamily: Style.text.font.main,
    fontWeight: 700,
    fontSize: 20,
    marginBottom: 10,
    width: "100%",
    textTransform: "uppercase",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 26,
      marginBottom: 15,
    },
  ),
);

export const itemDetail = s(
  {
    fontFamily: Style.text.font.second,
    fontSize: 16,
    lineHeight: "24px",
    color: "#434343",
    maxWidth: "80%",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: 18,
      lineHeight: "27px",
      maxWidth: "70%",
    },
  ),
);

export const quoteButton = s({
  margin: "auto",
});
