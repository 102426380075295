import { media, style as s } from "typestyle";
import * as Style from "../../styles";
//  service: "roof" | "fence" | "tree" | "patio" | "ground";

export const overlay = s({
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: 2,
  transition: ".2s ease-in",
  background: Style.colors.darkTranslucent300,
});

export const content = s({
  fontWeight: 500,
  fontSize: 20,
  lineHeight: "30px",
  color: "#F4F4F4",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.11)",
  textAlign: "center",
  transition: ".2s ease-in",
  zIndex: 3,
});

export const shared = s({
  borderRadius: 8,
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 12,
  cursor: "pointer",
  backgroundSize: "cover",
  backgroundPosition: "center",
  $nest: {
    "&:hover": {
      [`.${overlay}`]: {
        background: Style.colors.darkTranslucent100,
      },
      [`.${content}`]: {
        color: "#FFFFFF",
        lineHeight: "31px",
      },
    },
  },
});

export const roof = s({
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/CCL/colorado-roof-lighting.png)",
});

export const fence = s({
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/CCL/chaffee-county-colorad-fence-and-rail-lighting.png)",
});

export const tree = s({
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/CCL/holiday-lighting-service-colorado.png)",
});

export const patio = s({
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/CCL/chaffee-county-patio-outdoor-lighting.png)",
});

export const ground = s({
  backgroundImage:
    "url(https://ik.imagekit.io/yd113v8urnw4/CCL/chaffee-county-ground-floating-christmas-lights.png)",
});

export const link = s({
  textDecoration: "none",
  color: "inherit",
});
