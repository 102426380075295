import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  fontFamily: Style.text.font.main,
  width: "100%",
  position: "relative",
  paddingTop: 30,
});

export const title = s(
  {
    textTransform: "uppercase",
    position: "relative",
    textAlign: "center",
    width: "fit-content",
    margin: "auto",
    fontWeight: 400,
    fontSize: "5vw",
    color: "#171717",
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: "3vw",
    },
  ),
);

export const best = s(
  {
    fontSize: "4.5vw",
    fontWeight: 800,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      fontSize: "3.2vw",
    },
  ),
);

export const titleContent = s({
  zIndex: 3,
  position: "relative",
});

export const titleStripe = s({
  position: "absolute",
  bottom: 0,
  top: "20%",
  left: "-2%",
  background: `#7BBBCF`,
  zIndex: 2,
});

export const chaffee = s({
  color: Style.text.color.inverse,
  fontWeight: 500,
});

export const tiles = s(
  {
    padding: "2vw",
    marginBottom: 60,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  ),
);

export const tile = s(
  {
    width: "90%",
    margin: "0px auto 10px",
    minHeight: 130,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      width: "16vw",
      minHeight: "18vw",
      margin: "none",
    },
  ),
);
