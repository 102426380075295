import { media, style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s(
  {
    backgroundImage: `url("/assets/buena-vista-christmas-lights.jpg")`,
    // backgroundImage: `url("/assets/arkansas-river-valley-christmas-lights.jpg")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    fontFamily: Style.text.font.main,
    padding: "30px 20px",
    position: "relative",
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      width: "100%",
      padding: "142px 64px 96px",
    },
  ),
);

export const overlay = s({
  position: "absolute",
  inset: "0 0 0 0",
  zIndex: 2,
  backgroundColor: Style.colors.darkTranslucent300,
});

export const link = s({
  color: "inherit",
  textDecoration: "none",
});

export const content = s({
  zIndex: 3,
  position: "relative",
});

export const title = s(
  {
    fontWeight: 800,
    color: "#DBDBDB",
    textTransform: "uppercase",
    fontSize: 32,
    marginBottom: 20,
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      fontSize: 64,
      marginBottom: 30,
    },
  ),
);

export const subTitle = s(
  {
    fontFamily: Style.text.font.second,
    fontWeight: 500,
    fontSize: 20,
    color: "#D6D6D6",
    marginBottom: 30,
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      fontSize: 26,
      marginBottom: 20,
    },
  ),
);

export const buttons = s(
  {
    $nest: {
      div: {
        minWidth: 300,
        minHeight: 50,
        fontSize: 24,
        marginBottom: 20,
        width: "auto",
      },
    },
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    {
      display: "flex",
      marginBottom: 20,
      $nest: {
        div: {
          minWidth: 300,
          minHeight: 50,
          fontSize: 24,
          marginBottom: 20,
          width: "auto",
        },
      },
    },
  ),
);

export const phoneButton = s(
  {
    color: "#69A6B9",
    fontWeight: 800,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 24,
    background: "linear-gradient(180deg, #FFF4FD 15.62%, #E7EEF2 90.1%)",
    borderRadius: 4,
    $nest: {
      "&:hover": {
        background: Style.colors.translucent880,
        border: `1px solid ${Style.colors.primary}`,
        color: Style.colors.primary,
      },
    },
  },
  media(
    {
      minWidth: Style.screensize.mdMin,
    },
    { marginRight: 30 },
  ),
);
