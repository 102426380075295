import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  position: "fixed",
  padding: `7px 15px 0px 10px`,
  top: 0,
  left: 0,
  right: 0,
  height: 70,
  zIndex: 10,
  backgroundColor: "#EEEEEE",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontFamily: Style.text.font.main,
});

export const menu = s({
  width: 33,
  height: 50,
});

export const modal = s({
  background: Style.colors.blue,
  opacity: 0.9,
  padding: "30px 40px",
  fontFamily: Style.text.font.main,
});

export const burger = s({
  zIndex: 1010,
});

export const navItem = s({
  fontSize: Style.text.fontSize.medium,
  textTransform: "uppercase",
  marginBottom: 20,
  fontWeight: 600,
  color: Style.text.color.main,
  $nest: {
    "&:hover": {
      cursor: "pointer",
      fontWeight: 400,
      color: Style.text.color.inverseSubtle,
    },
  },
  transition: ".3s ease-out",
});

export const link = s({
  textDecoration: "none",
  color: Style.colors.black3,
});

export const dropDown = s({
  fontSize: Style.text.fontSize.medium,
  textTransform: "uppercase",
  marginBottom: 20,
  fontWeight: 400,
  color: Style.text.color.main,
  height: "auto",
});
