export const white = "#fff";
export const white2 = "#F2F2F2";
export const white3 = "#F5F3F3";

export const black1 = "#000";
export const black2 = "#121212";
export const black3 = "#2D283E";
export const black4 = "#D0D0D0";

export const translucent950 = "rgba(255, 255, 255, 0.95)";
export const translucent880 = "rgba(255, 255, 255, 0.88)";
export const translucent850 = "rgba(255, 255, 255, 0.85)";
export const translucent650 = "rgba(255, 255, 255, 0.65)";
export const translucent400 = "rgba(255, 255, 255, 0.4)";
export const translucent150 = "rgba(255, 255, 255, 0.15)";

export const darkTranslucent20 = "rgba(0, 0, 0, 0.02)";
export const darkTranslucent30 = "rgba(0, 0, 0, 0.03)";
export const darkTranslucent100 = "rgba(0, 0, 0, 0.1)";
export const darkTranslucent200 = "rgba(0, 0, 0, 0.2)";
export const darkTranslucent250 = "rgba(0, 0, 0, 0.25)";
export const darkTranslucent300 = "rgba(0, 0, 0, 0.3)";
export const darkTranslucent500 = "rgba(0, 0, 0, 0.5)";
export const darkTranslucent650 = "rgba(0, 0, 0, 0.65)";
export const darkTranslucent750 = "rgba(0, 0, 0, 0.75)";
export const darkTranslucent950 = "rgba(0, 0, 0, 0.95)";

export const shade200 = "#9B9B9B";
export const shade400 = "#535353";
export const shade600 = "#333";
export const shade700 = "#242424";

export const primary = "#7FCCF8";
export const second = "#29A457";
export const third = "#F3C716";

export const navy = "#001641";
export const blue = primary;
export const green = second;
export const yellow = third;
export const lightBrown = "#CC8454";
export const beige = "#C2B7A4";

export const background = "#0A0A0A";
export const error = "#FF0033";
