import * as React from "react";
import * as style from "./Process.style";
import * as Components from "../../../components";
import { IKImage } from "imagekitio-react";
import { hooks } from "../../../utility";
import salidaXmasLights from "../../../../public/assets/gunneson-christmas-lights.jpg";
import bvLightInstallation from "../../../../public/assets/safe-xmas-installation-southern-colorado.jpg";
const Process: React.FC = () => {
  const display = hooks.useDisplayContext();
  return (
    <>
      <div className={style.component}>
        {display.isMobile && <div className={style.overlay} />}
        <div className={style.leftSection}>
          <div className={style.title}>Our Process</div>
          <div className={style.item}>
            <div className={style.number} style={{ width: 50 }}>
              1
            </div>
            <div>
              <div className={style.itemTitle}>Design</div>
              <div className={style.itemDetail}>
                When you schedule your estimate, we will meet with you to
                discuss potential designs and work together to define your ideal
                install.
              </div>
            </div>
          </div>
          <div className={style.item}>
            <div className={style.number} style={{ width: 60 }}>
              2
            </div>
            <div>
              <div className={style.itemTitle}>Install</div>
              <div className={style.itemDetail}>
                Our team of incredibly talented installers will come custom cut
                &amp; fit our professional LED lights to your home or business.
              </div>
            </div>
          </div>
          <div className={style.item}>
            <div className={style.number} style={{ width: 68 }}>
              3
            </div>
            <div>
              <div className={style.itemTitle}>Maintenance</div>
              <div className={style.itemDetail}>
                Give us a shout if a light goes out! We will come fix any
                problem with your display within 24 hours or the next business
                day at no cost to you.
              </div>
            </div>
          </div>
          <div className={style.item}>
            <div className={style.number}>4</div>
            <div>
              <div className={style.itemTitle}>Removal and Storage</div>
              <div className={style.itemDetail}>
                We come back in January to take down the lights and store them
                with care for the next year.
              </div>
            </div>
          </div>
        </div>
        {!display.isMobile && (
          <div className={style.rightSection}>
            <img
              className={style.mainImage}
              src={salidaXmasLights}
              alt="chaffee county tree lighting"
            />
            <IKImage
              className={style.logo}
              alt="colorado arkansas valley christmas lights logo"
              path="/logo-night.png"
            />
            <img
              className={style.subImage}
              src={bvLightInstallation}
              alt="colorado chaffee christmas lights"
            />
            <Components.QuoteButton className={style.quoteButton} />
          </div>
        )}
      </div>
    </>
  );
};

Process.displayName = "Process";
export { Process };
